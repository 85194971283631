(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("konva"), require("magic-wand-tool"), require("jszip"));
	else if(typeof define === 'function' && define.amd)
		define(["konva", "konmagic-wand-tool", "jszip"], factory);
	else if(typeof exports === 'object')
		exports["dwv"] = factory(require("konva"), require("magic-wand-tool"), require("jszip"));
	else
		root["dwv"] = factory(root["Konva"], root["MagicWand"], root["JSZip"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__944__, __WEBPACK_EXTERNAL_MODULE__324__, __WEBPACK_EXTERNAL_MODULE__654__) {
return 